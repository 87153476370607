<template>
  <HeaderMovieLoading ref="headerMovieLoading" />
  <div v-if="error">{{ error }}</div>


  <div class="file-upload">
    <!-- ajouter icône pi-folder-open -->
    <label for="file-upload" class="custom-file-upload" :class="{ 'disabled': isLoading }">
      <i class="pi pi-folder-open" style="font-size: 1.2rem"></i>
      Choisir mon dossier de films...
    </label>
    <input id="file-upload" type="file" @change="handleFileUpload" directory webkitdirectory multiple
      style="display: none;" :disabled="isLoading" />
  </div>

  <div v-if="isLoading" class="main-loader"><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></div>

  <FileList :isFilesSectionVisible="true" :fileList="filteredFileList" :tag="tag" description="fichiers &agrave; traiter" @file-processed="onFileProcessed" />

  <FileList :isFilesSectionVisible="false" :fileList="filteredExistingFileList" :tag="tag" description="fichiers d&eacute;j&agrave; existants" @file-processed="onFileProcessed" />

  <FileList :isFilesSectionVisible="false" :fileList="filteredIgnoredFileList" :tag="tag" description="fichiers &agrave; ignorer" @file-processed="onFileProcessed" />

  <!-- add an horizontal line-->
  <div v-if="newMovies.length > 0">
    <hr class="hr-separator" />
    <h2>Films ajout&eacute;s</h2>
    <FilmGrid :films="newMovies" :loggedIn="false" :popinRouteName="popinRouteName" :searchMode="false" />
  </div>

  <!-- add an horizontal line-->
  <div v-if="moviesToBeDeleted.length > 0">
    <hr class="hr-separator" />
    <h2>Films &agrave; supprimer</h2>
    <Button icon="pi pi-times" label="Delete" severity="danger" ref="deleteButton"
      @click="deleteMovies"></Button>

    <FilmGrid :films="moviesToBeDeleted" :loggedIn="false" :popinRouteName="popinRouteName" :searchMode="false" />
  </div>

  <router-view></router-view>
</template>

<script>
import axios from 'axios';
import { getHeaders } from '@/mixins/auth';

import Button from 'primevue/button';

import FilmGrid from './FilmGrid.vue';
import HeaderMovieLoading from './HeaderMovieLoading.vue';
import FileList from './FileList.vue';

export default {
  name: 'MovieCatalog',
  components: {
    Button, 
    HeaderMovieLoading,
    FilmGrid,
    FileList,
  },
  data() {
    return {
      fileList: [
      ],

      popinRouteName: "AddedFilmPopin",

      isLoading: false,
      error: null,
      tag: this.generateTag(),

      newMovies: [],
      moviesToBeDeleted: [],

      display: false,
      deleteButton: null,
    };
  },
  computed: {
    filteredFileList() {
      return this.fileList.filter(file => !file.isProcessed && (!file.status || (file.status!=="ALREADY_EXISTED" && file.status!=="IGNORED")));
    },
    filteredExistingFileList() {
      return this.fileList.filter(file => !file.isProcessed && (file.status && file.status==="ALREADY_EXISTED"));
    },
    filteredIgnoredFileList() {
      return this.fileList.filter(file => file.status && file.status==="IGNORED");
    },
  },
  methods: {
    generateTag() {
      //create a random number based on current timestamp + random number
      const timestamp = (new Date().getTime() / 1000 | 0).toString(16);
      const random = (Math.random() * 16 | 0).toString(16);
      const tag = timestamp + random;
      return tag.toString();
    },
    async handleFileUpload(event) {
      console.log('handleFileUpload');
      const movieExtension = new Array(".avi", ".mkv", ".mp4");

      this.isLoading = true;
      this.fileList = Array.from(event.target.files).map(file => {
        return {
          name: file.name,
          path: file.webkitRelativePath,
          size: file.size,
          isProcessed: false,
          status: null,
        };
      });
      // filter out non-movie files
      this.fileList = this.fileList.filter(file => {
      return movieExtension.some(ext => file.name.endsWith(ext));
      });

      //delete duplicates on file name
      this.fileList = this.fileList.filter((file, index, self) =>
        index === self.findIndex((t) => (t.name === file.name))
      );

      // update exists property of each file using checkFileOnline function
      await Promise.all(this.fileList.map(async file => {
        let status = await this.checkFileOnline(file.name);
        console.debug('checkFileOnline response:', status);
        file.status = status;
      }));

      this.isLoading = false;
    },

    async checkFileOnline(fileName) {
      // Logic to send the file online
      console.debug('Checking file online:', fileName);

      // call api /api/movies/search_file/:file_name
      // if success, status = completed
      // if error, status = error

      const encodedFileName = encodeURIComponent(fileName);
      const url = `${this.$apiUrl}file/status/${encodedFileName}`;

      try {
        const response = await axios.get(url, getHeaders());
        console.debug('File:', fileName, 'status:', response.data.status);
        return (response.data.status);
      } catch (error) {
        console.error('error:', error);
        return null;
      }
    },

    onFileProcessed(filePath, status, movie) {
      // Find the file in the fileList
      const file = this.fileList.find(file => file.path === filePath);

      if (file) {
        if (status === "ALREADY_EXISTED" || status === "IGNORED" || status === "ADDED") {
          file.isProcessed = true;
          file.status = status;
        }
        if (status === "pending") {
          file.status = status;
          file.isProcessed = false;
        }
        if (status === "ADDED") {
          this.newMovies.push(movie);
        }
      }
    },

    async sendAllFiles() {
      console.log("sendAllFiles");
      const promises = this.fileList.filter(file => !file.isProcessed).map((file, index) => {
        if (!file.isProcessed) {
          // call sendFileOnline on the MovieFile component
          return this.$refs.movieFile[index].sendFileOnline();
        }
      });

      await Promise.all(promises);

      this.getUntaggedMovies();
    },

    getUntaggedMovies() {
      const url = `${this.$apiUrl}movies/untagged/${this.tag}`;
      axios.get(url, getHeaders())
        .then((response) => {
          console.log('response:', response.data);
          this.moviesToBeDeleted = response.data.movies;
        })
        .catch((error) => {
          console.log('error:', error);
          this.error = error;
        });
    },

    deleteMovies() {
      const url = `${this.$apiUrl}movies/untagged/${this.tag}`;
      axios.delete(url, getHeaders())
        .then((response) => {
          console.log('response:', response.data);
          this.getUntaggedMovies();
        })
        .catch((error) => {
          console.log('error:', error);
          this.error = error;
        });
    },
  }
};
</script>

<style>
.main-loader {
  display: flex;
  align-items: center;
  /* Centre verticalement */
  justify-content: center;
  /* Centre horizontalement si nécessaire */
  height: 300px;
  /* ou toute autre hauteur */
  color: #888;
}

.file-upload {
  padding: 10px;
  margin: 3px;
  margin-bottom: 20px;

  display: flex;
  justify-content: center;
}

.custom-file-upload {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.custom-file-upload:hover {
  background-color: #45a049;
}

/* if disabled, display a greyed out button */
.custom-file-upload.disabled {
  background-color: #f1f1f1;
  color: #888;
  cursor: not-allowed;
}

.custom-file-upload.disabled:hover {
  background-color: #f1f1f1;
  color: #888;
  cursor: not-allowed;
}

hr-separator {
  margin: 20px 0px;
}

.send-button {
  margin-left: 10px;

  background-color: #4CAF50;
  cursor: pointer;
  color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 6px 6px 3px 6px; /* top right bottom left */
}

.send-button:hover {
  background-color: #45a049;
}
</style>
<template>
    <div class="movie-file">
        <div class="status-icon">
            <!-- Display the icon corresponding to the status -->
            <i :class="getStatusIcon(status)"></i>
        </div>
        <div class="filename">
            {{ file.name }}
        </div>
        <button v-if="status==='pending'" class="search-button" @click="openSearchPopin()">
            <i class="pi pi-search"></i>
        </button>
        <button v-if="status!=='IGNORED'"  class="ignore-button" @click="ignoreFile()">
            <i class="pi pi-eraser"></i>
        </button>
        <button v-if="status==='IGNORED'" class="ignore-button" @click="unignoreFile()">
            <i class="pi pi-filter-slash"></i>
        </button>
        <button class="send-button" @click="sendFileOnline">
            <i class="pi pi-send"></i>
        </button>
    </div>
</template>

<script>
import axios from 'axios';
import { getHeaders } from '@/mixins/auth';

export default {
    name: 'MovieFile',
    props: {
        file: Object,
        tag: String,
        index: Number,
        initialStatus: String,
    },
    data() {
        return {
            status: this.initialStatus, // "pending", "IGNORED", "NOT_FOUND", "ADDED" or "ALREADY_EXISTED"
        };
    },
    mounted() {
        console.debug('MovieFile: mounted - file:', this.file.name);
    },
    emits: ['file-processed'],
    methods: {
        getStatusIcon(status) {
            // Return the icon class based on the status
            if (status === 'pending') {
                return 'pi pi-clock status-pending';
            } else if (status === 'ALREADY_EXISTED') {
                return 'pi pi-check-circle status-existed';
            } else if (status === 'ADDED') {
                return 'pi pi-check-circle status-added';
            } else {
                return 'pi pi-exclamation-circle status-error';
            }
        },
        sendFileOnline() {
            console.log('Sending file online:', this.file);

            const url = `${this.$apiUrl}movies/upsert`;
            const file_tag = {
                file_path: this.file.path,
                file_name: this.file.name,
                tag: this.tag,
            };

            return axios.post(url, file_tag, getHeaders())
                .then((response) => {
                    console.log('response:', response.data);
                    this.status = response.data.status;

                    this.updateMovieDisplay(response.data.status, response.data.movie);
                })
                .catch((error) => {
                    console.log('error:', error);
                    this.status = 'ERROR';
                });
        },
        ignoreFile() {
            console.log('Ignoring file:', this.file);

            const url = `${this.$apiUrl}ignored_movies`;
            const file_to_ignore = {
                file_name: this.file.name,
            };

            return axios.post(url, file_to_ignore, getHeaders())
                .then((response) => {
                    if(response.status === 201) {
                        this.status = 'IGNORED';
                        this.updateMovieDisplay(this.status, null);
                    }
                })
                .catch((error) => {
                    console.log('error:', error);
                });
        },
        unignoreFile() {
            console.log('Ignoring file:', this.file);

            const url = `${this.$apiUrl}ignored_movies/${this.file.name}`;

            return axios.delete(url, getHeaders())
                .then((response) => {
                    if(response.status === 204) {
                        this.status = 'pending';
                        this.updateMovieDisplay(this.status, null);
                    }
                })
                .catch((error) => {
                    console.log('error:', error);
                });
        },
        updateMovieDisplay(status, movie) {
            // If status = "ALREADY_EXISTED", indicate it to the parent component
            if (status === 'ALREADY_EXISTED' || status === 'ADDED' || status === 'IGNORED' || status === 'pending') {
                this.$emit('file-processed', this.file.path, status, movie);
            }
        },
        
        openSearchPopin() {
            console.log("Click on search for file: " + this.file.name);
            this.$router.push({ name: 'SearchPopin', params: { file_name: this.file.name, file_path: this.file.path, tag: this.tag } });
        },
    },
};
</script>

<style scoped>
.movie-file {
    display: flex;
    align-items: center;
}

.status-icon {
    margin-right: 10px;
}

.filename {
    flex-grow: 1;
}

.search-button {
    margin-left: 5px;

    background-color: #007bff;
    color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 6px 6px 3px 6px; /* top right bottom left */
}

.search-button:hover {
    background-color: #0056b3;
}

.search-button i {
    font-size: 0.8rem;
    cursor: pointer;
}

.send-button {
    margin-left: 5px;

    background-color: #4CAF50;
    color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 6px 6px 3px 6px; /* top right bottom left */
}

.send-button:hover {
    background-color: #45a049;
}

.send-button i {
    font-size: 0.8rem;
    cursor: pointer;
}

.ignore-button {
    margin-left: 5px;

    background-color: #e91e63;
    color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 6px 6px 3px 6px; /* top right bottom left */
}

.ignore-button:hover {
    background-color: #920c39;
}

.ignore-button i {
    font-size: 0.8rem;
    cursor: pointer;
}

.status-existed {
    color: #888;
}

.status-added {
    color: #14aa5f;
}

.status-pending {
    color: #888;
}

.status-error {
    color: #e91e63;
}
</style>

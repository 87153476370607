<template>
    <div class="header">
        <h1>Chargement de films</h1>

        <div class="home-container">
            <button class="home-button" @click="onHomeClick">
                <!-- Si l'utilisateur n'est pas connecté, afficher "Connexion" -->
                <i class="pi pi-home"></i>
            </button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'HeaderMovieLoading',
    methods: {
        onHomeClick() {
            this.$router.push({ name: 'Home' });
        },
    },
};
</script>

<style>
@media (max-width: 600px) {
    .header {
        flex-wrap: wrap;
        order: 1;
    }

    .header h1 {
        flex-basis: 30%;
        flex-grow: 1;
        flex-shrink: 0;
        order: 1;
    }

    .home-container {
        flex-grow: 0;
        flex-shrink: 0;
        text-align: right;

        position: relative;
        order: 2;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.header h1 {
    font-size: 2em;
    flex-grow: 1;
    margin-top: 5px;
    margin-bottom: 5px;
}

.home-container {
    flex-grow: 0;
    position: relative;

}

.home-button {
    position: relative;

    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin-left: 10px;
    cursor: pointer;
}
</style>
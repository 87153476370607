<template>
    <div class="header">
        <h1>Films</h1>

        <FilterSortContainer class="filter-sort-container" :loggedIn="loggedIn" @criteria-changed="onCriteriaChanged" />

        <div v-if="!loggedIn" class="login-container">
            <button class="login-button" @click="toggleLogin">
                <!-- Si l'utilisateur n'est pas connecté, afficher "Connexion" -->
                <span>Connexion </span>&nbsp;
                <i :class="loggedIn ? 'pi pi-sign-out' : 'pi pi-sign-in'"></i>
            </button>
            <LoginPopin :isVisible="showLoginPopin" @login-success="onLogin" />

        </div>
        <div v-if="loggedIn" class="login-container">
            <SpeedDial v-if="loggedIn" :model="items" direction="down" showIcon="pi pi-user" hideIcon="pi pi-times"
                buttonClass="p-button-outlined" :tooltipOptions="{ position: 'left' }" :transitionDelay="80"
                class="login-button" />
        </div>
    </div>
    <Toast position="bottom-right" />
</template>

<script>
import LoginPopin from './LoginPopin.vue';
import FilterSortContainer from './FilterSortContainer.vue';

import SpeedDial from 'primevue/speeddial';

import Toast from 'primevue/toast';

export default {
    name: 'HeaderCatalog',
    emits: ['criteria-changed', 'login-changed'],
    components: {
        LoginPopin, FilterSortContainer,
        SpeedDial,
        Toast,
    },
    data() {
        return {
            loggedIn: false, // Logique d'authentification
            loggedUser: null,
            showLoginPopin: false,

            items: [
                {
                    label: 'Charger les films',
                    icon: 'pi pi-file-import',
                    command: () => {
                        this.loadMovies();
                    }
                },
                {
                    label: 'Déconnexion',
                    icon: 'pi pi-sign-out',
                    command: () => {
                        this.logout();
                    }
                }
            ],
        }
    },
    mounted() {
        this.restoreLoggedUser();
    },
    methods: {
        toggleLogin() {
            console.log("Toggle login called");
            if (!this.loggedIn) {
                this.showLoginPopin = !this.showLoginPopin;
            }
            else {
                this.logout();
            }
        },
        onLogin(credentials) {
            console.log('Login: ', credentials);
            this.loggedUser = localStorage.loggedUser;
            this.showLoginPopin = false;
            this.loggedIn = true;

            this.$toast.add({
                severity: 'success', // 'success', 'info', 'warn' ou 'error'
                summary: 'Connecté',
                detail: credentials + ' est maintenant connecté !',
                life: 1500, // Durée d'affichage du toast en millisecondes
            });
            this.$emit('login-changed', true);
        },
        onCriteriaChanged(criteria) {
            console.log("HeaderCatalog: onCriteriaChanged");
            this.$emit('criteria-changed', criteria);
        },
        logout() {
            this.$toast.add({
                severity: 'error', // 'success', 'info', 'warn' ou 'error'
                summary: 'Déconnecté',
                detail: this.loggedUser + ' est maintenant déconnecté !',
                life: 1500, // Durée d'affichage du toast en millisecondes
            });

            localStorage.removeItem('loggedUser');
            localStorage.removeItem('token');
            this.loggedIn = false;
            this.$emit('login-changed', false);
        },
        restoreLoggedUser() {
            if (localStorage.token && localStorage.loggedUser) {
                this.loggedIn = true;
                this.loggedUser = localStorage.loggedUser;

                this.$emit('login-changed', true);
            }
        },
        raiseError(message) {
            this.$toast.add({
                severity: 'error', // 'success', 'info', 'warn' ou 'error'
                summary: 'Erreur',
                detail: message,
                life: 1500, // Durée d'affichage du toast en millisecondes
            });
        },

        loadMovies() {
            this.$router.push({ name: 'MovieLoadingPage' });
        },
    },
};
</script>

<style>
@media (max-width: 600px) {
    .header {
        flex-wrap: wrap;
        order: 1;
    }

    .header h1 {
        flex-basis: 30%;
        flex-grow: 1;
        flex-shrink: 0;
        order: 1;
    }

    .login-container {
        flex-grow: 0;
        flex-shrink: 0;
        text-align: right;

        position: relative;
        order: 2;
    }

    .filter-sort-container {
        order: 3;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.header h1 {
    font-size: 2em;
    flex-grow: 1;
    margin-top: 5px;
    margin-bottom: 5px;
}

.login-container {
    flex-grow: 0;
    position: relative;

}

.login-button {
    position: relative;

    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin-left: 10px;
    cursor: pointer;
}

.filter-container {
    display: flex;
    align-items: center;
    /*background: #fff;
    border: 1px solid #ccc;
    border-radius: 20px;*/
    overflow: hidden;
}

/* Styles pour rendre le bouton SpeedDial rond et de même hauteur que sort-container */
.p-speeddial .p-speeddial-button {
    box-shadow: none;
    /* Enlève l'ombre, si elle est présente */
    color: #838383;
    background-color: #e9e9ed;
    border-color: #ccc;

    border-radius: 50%;
    height: 40px;
    /* Ajustez cette valeur pour correspondre à la hauteur de votre sort-container */
    width: 40px;
    /* Même valeur que la hauteur pour un cercle parfait */
}

/* Positionner les boutons items sous le bouton SpeedDial */
.p-speeddial .p-speeddial-list {
    position: absolute;
    top: 100%;
    /* Positionnez juste en dessous du bouton SpeedDial */
    left: 50%;
    transform: translateX(-50%);
    /* Centrer les boutons sous le bouton SpeedDial */
    z-index: 3;
    /* Assurez-vous que les boutons sont par-dessus le FilmGrid */
}

/* Enlève le cadre et l'ombre du conteneur SpeedDial */
.p-speeddial {
    box-shadow: none;
    border: none;
    padding: 0;
}
</style>
<template>
    <transition name="popin" enter-active-class="popin-enter-active" leave-active-class="popin-leave-active"
        enter-from-class="popin-enter-from" enter-to-class="popin-enter-to" leave-from-class="popin-leave-from"
        leave-to-class="popin-leave-to">
        <div class="modal" @click="backgroundClick">
            <div class="modal-content" @click.stop="insideClick">
                <span class="close" @click="closePopin">&times;</span>
                <h2 class="modal-title">{{ file_name }}</h2>

                <div class="search-popin">
                    
                    <form @submit.prevent="search">
                        <div class="form-group">
                            <label for="movieName">Titre du film&nbsp;:</label>
                            <input type="text" id="movieName" v-model="movieName" />
                        </div>
                        <div class="form-group">
                            <label for="year">Ann&eacute;e de sortie&nbsp;:</label>
                            <input type="text" id="year" v-model="year" @input="forceInteger"/>
                        </div>
                        <button type="submit">
                            <i class="pi pi-search"></i>
                            Recherche
                        </button>
                    </form>
                </div>

                <FilmGrid v-if="foundMovies.length > 0" :films="foundMovies" :loggedIn="false" popinRouteName="" :searchMode="true"
                    @selectMovie="onSelectMovie" />
            </div>
        </div>
    </transition>
</template>

<script>
import axios from 'axios';
import { getHeaders } from '@/mixins/auth';

import FilmGrid from './FilmGrid.vue';

export default {
    name: 'SearchPopin',
    components: {
        FilmGrid,
    },
    props: {
        file_name: String,
        file_path: String,
        tag: String,
    },
    data() {
        return {
            movieName: '',
            year: '',
            foundMovies: [],
        };
    },
    methods: {
        closePopin() {
            this.$router.go(-1);
        },
        backgroundClick() {
            this.closePopin();
        },
        forceInteger() {
            this.year = this.year.replace(/\D/g, '');
        },
        search() {
            console.log('Search for movie title=', this.movieName, ' - year=', this.year);

            const encodedMovieName = encodeURIComponent(this.movieName);
            const url = `${this.$apiUrl}search_movie`;
            
            const params = {
                title: encodedMovieName,
                year: this.year,
            };
            const headers = getHeaders().headers;

            axios.get(url, { headers, params: params })
                .then(response => {
                    if (response.data && response.data.total_results > 0) {
                        this.foundMovies = response.data.results;
                    } else { 
                        console.log('No movie found');
                    }
                })
                .catch(error => {
                    console.error('Error while searching:', error);
                });
        },
        onSelectMovie(movie) {
            console.log('Selected movie:', movie.id);
            
            const url = `${this.$apiUrl}movies/addById`;
            const file_tag = {
                file_path: this.file_path,
                file_name: this.file_name,
                tag: this.tag,
                movie_id: movie.id,
            };

            axios.post(url, file_tag, getHeaders())
                .then((response) => {
                    console.log('response:', response.data);
                    this.status = response.data.status;

                    this.$router.go(-1);
                })
                .catch((error) => {
                    console.log('error:', error);
                    this.status = 'ERROR';
                });
        },
    }
};
</script>

<style scoped>
/* Transition classes */
.popin-enter-active,
.popin-leave-active {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* État initial pour l'entrée */
.popin-enter-from,
.popin-leave-to {
    opacity: 0;
    /* transform: translateY(20px); */
}

/* État final pour l'entrée */
.popin-enter-to,
.popin-leave-from {
    opacity: 1;
}

/* Style for modal */
.modal {
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.modal-content {
    background-color: #fefefe;
    margin: 1.75rem auto;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    max-width: 1140px;
    /* Could be more or less, depending on screen size */
}

.popin-loader {
    display: flex;
    align-items: center;
    /* Centre verticalement */
    justify-content: center;
    /* Centre horizontalement si nécessaire */
    height: 300px;

    color: #888;
}

.modal-title {
    font-weight: normal;
    margin-top: 0;
}

/* Style pour les groupes de formulaire */
.form-group {
    margin-bottom: 15px;
}

input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

input[type="text"]:focus {
    outline: none;
    border-color: #666;
    box-shadow: 0 0 8px #666;
}

button {
    background-color: #4CAF50; /* Green */
    width: 100%;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right; /* Alignement à droite */
    margin-bottom: 20px;
}

button:hover {
    background-color: #45a049;
}

button i {
    margin-right: 5px;
}

.search-popin {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: auto;
}

.modal-title {
    text-align: center;
    color: #333;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

</style>
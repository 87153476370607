<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
</script>

<style>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  background-color: rgb(217, 217, 217);
  /* #f4f4f4; */
  margin: 0;
  padding: 20px;
}

.main-loader {
  display: flex;
  align-items: center;
  /* Centre verticalement */
  justify-content: center;
  /* Centre horizontalement si nécessaire */
  height: 300px;
  /* ou toute autre hauteur */
  color: #888;
}
</style>
import { createRouter, createWebHistory } from 'vue-router';

import { verifyToken } from './mixins/auth.js';

import MovieCatalog from './components/MovieCatalog.vue';
import FilmPopin from './components/FilmPopin.vue';
import SearchPopin from './components/SearchPopin.vue';

import MovieLoadingPage from './components/MovieLoadingPage.vue';

const routes = [
  { 
    path: '/', 
    component: MovieCatalog,
    name: 'Home', 
    meta: {
      title: 'Films' 
    },
    children: [
      {
        path: 'movie/:id',
        name: 'FilmPopin',
        component: FilmPopin,
      }
    ],
  },
  { 
    path: '/load_movies',
    component: MovieLoadingPage,
    name: 'MovieLoadingPage',
    meta: {
      title: 'Chargement des films', 
      requiresAuth: true,
    },
    children: [
      {
        path: 'newmovie/:id',
        name: 'AddedFilmPopin',
        component: FilmPopin,
      },
      {
        path: 'search/:file_name/:tag/:file_path',
        props: true, // permet de passer les props dans l'url
        name: 'SearchPopin',
        component: SearchPopin,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes // raccourci pour `routes: routes`
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Films';
  if (to.meta.requiresAuth && !verifyToken()) {
    next({ name: 'Home' });
  }
  next();
});

export default router;
export function verifyToken() {
    if (localStorage.getItem('token')) {
        // TODO: Votre logique de vérification de token ici
        return true;
    } else {
        return false;
    }
}

export function getHeaders() {
    const headers = {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}` // Assurez-vous que le token est stocké ici
        }
    };
    return headers;
}
<template>
    <!-- Modal Structure -->
    <transition name="popin" enter-active-class="popin-enter-active" leave-active-class="popin-leave-active"
        enter-from-class="popin-enter-from" enter-to-class="popin-enter-to" leave-from-class="popin-leave-from"
        leave-to-class="popin-leave-to">
        <div class="filmModal" @click="backgroundClick">
            <!-- Modal content while loading or in error -->
            <div class="filmModal-content" @click.stop="insideClick" :style="getBackgroundStyle()">
                <span class="close" @click="close">&times;</span>
                <h2 class="filmModal-title"><span v-if="filmDetails">{{ filmDetails.title }} </span><span
                        v-if="!isLoading && !error && getOriginalTitle() != ''" class="original-title">({{
                            getOriginalTitle() }})</span></h2>
                <div v-if="isLoading" class="popin-loader"><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
                </div>
                <p v-if="!isLoading && error">Error: {{ error }}</p>

                <div v-if="!isLoading && !error" class="content">
                    <div class="movie-poster">
                        <img :src="`${this.$posterUrl}${filmDetails.poster_path}`"
                            alt="Affiche du film {{ filmDetails.title }}">
                    </div>
                    <div class="movie-info">
                        <h4>
                            <em>{{ filmDetails.tagline }}</em>
                        </h4>
                        <h6>{{ filePath }}
                        </h6>
                        <span class="popin-badge popin-duration"><i class="pi pi-clock" style="font-size: 0.7rem;"></i> {{
                            formatDuration(filmDetails.runtime)
                        }}</span>
                        <span class="popin-badge popin-releasedate"><i class="pi pi-calendar"
                                style="font-size: 0.7rem;"></i> {{
                                    formatDate(filmDetails.release_date)
                                }}</span>
                        <i class="pi pi-angle-right" style="font-size: 0.7rem;"></i>
                        <span v-for="genre in filmDetails.genres" :key="genre" class="popin-badge popin-genre">{{ genre.name
                        }}</span>
                        <hr>
                        <p>{{ filmDetails.overview }}</p>
                        <!-- Ajout du lien vers la fiche du film -->
                        <a :href="`https://www.themoviedb.org/movie/${filmDetails.id}`" target="_blank" class="film-details-link">
                            Plus de détails <i class="pi pi-external-link"></i>
                        </a>
                        <hr>
                        <div class="actors">
                            <button @click="actorsSwitch">
                                <i ref="iconActorsSwitch" class="pi pi-arrow-circle-down"></i>
                            </button>
                            <span v-for="actor in filmDetails.actors_1" :key="actor.id" class="actor">{{ actor.name }} <i>
                                    {{ actor.character }}</i></span>
                            <template v-for="actor in filmDetails.actors_2" :key="actor.id">
                                <transition name="fadeActor">
                                    <span v-if="showAllActors" class="actor">
                                        {{ actor.name }} <i>{{ actor.character }}</i>
                                    </span>
                                </transition>
                            </template>
                        </div>
                    </div>
                </div>
                <div v-if="!isLoading && !error && filmDetails.videos && filmDetails.videos.length > 0" class="footer">

                    <div v-if="!videoPlayed && filmDetails.videos[0].site.toLowerCase() == 'youtube'" class="youtube-player"
                        @click="playVideo">
                        <div :data-id="filmDetails.videos[0].key">
                            <img :src="`https://i.ytimg.com/vi/${filmDetails.videos[0].key}/hqdefault.jpg`"
                                alt="YouTube Video Thumbnail">
                            <div class="play">
                                <div style="position: relative; display: inline-block;">
                                    <i class="pi pi-stop"
                                        style="position: absolute; transform: translate(-50%, -50%); font-size: 4rem; color:white;"></i>
                                    <i class="pi pi-play"
                                        style="position: absolute; transform: translate(-45%, -50%); font-size: 2rem; color:white;"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="videoPlayed" class="video-wrapper">
                        <iframe :src="`https://www.youtube.com/embed/${filmDetails.videos[0].key}?autoplay=1&rel=0`"
                            frameborder="0" allowfullscreen="1"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            class="real-youtube-player" />
                    </div>
                </div>

                <!--
                <div>
                    <iframe width="560" height="315" src="" frameborder="0" allowfullscreen></iframe>
                </div>-->
            </div>
        </div>
    </transition>
</template>

<script>
import axios from 'axios';
import { formatDurationMixin } from '../mixins/formatDurationMixin';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export default {
    name: 'FilmPopin',
    data() {
        return {
            filmDetails: null,
            isLoading: true,
            error: null,
            showAllActors: false,
            videoPlayed: false,
        };
    },
    computed: {
        filePath() {
            if (this.filmDetails) {
                if (this.filmDetails.file_path == null || this.filmDetails.file_path == "") {
                    return this.filmDetails.file_name;
                }
                else {
                    return this.filmDetails.file_path + "/" + this.filmDetails.file_name;
                }
            }
            return "";
        }
    },
    mixins: [formatDurationMixin],
    mounted() {
        const filmId = this.$route.params.id;
        console.log("Popin opened for movie: " + filmId);

        this.fetchFilm(filmId);
    },
    methods: {
        close() {
            console.log("Close popin");

            this.$router.go(-1);
        },
        backgroundClick() {
            this.close();
        },
        insideClick() {
            // Vous pouvez gérer les clics à l'intérieur de la popin ici si nécessaire
            // Actuellement, cette méthode ne fait rien, mais arrête la propagation
            // pour éviter que la popin ne se ferme lors d'un clic à l'intérieur
        },
        fetchFilm( filmId ) {
            this.isLoading = true;
            this.error = null;
            axios.get(this.$apiUrl + 'movies/' + filmId)
                .then(response => {
                    console.log("Response received: " + JSON.stringify(response.data));
                    this.filmDetails = response.data;

                    if (this.filmDetails.credits !== undefined && this.filmDetails.credits.length > 0) {
                        this.filmDetails.credits.sort(function (a, b) {
                            return a.order - b.order;
                        });
                    }
                    this.filmDetails.actors_1 = this.filmDetails.credits.slice(0, 5);
                    this.filmDetails.actors_2 = this.filmDetails.credits.slice(5,);

                    this.isLoading = false;

                })
                .catch((error) => {
                    let message = 'An error occurred';
                    if (error.response) {
                        // La requête a été faite et le serveur a répondu avec un statut hors de la plage 2xx
                        message += `: ${error.response.status} ${error.response.data}`;
                    } else if (error.request) {
                        // La requête a été faite mais aucune réponse n'a été reçue
                        message += ': No response from the server';
                    } else {
                        // Quelque chose s'est produit lors de la mise en place de la requête qui a déclenché une erreur
                        message += ': ' + error.message;
                    }

                    this.error = message;
                    this.isLoading = false;
                });
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            return format(date, 'd MMMM yyyy', { locale: fr });
        },
        getBackgroundStyle() {
            if (this.filmDetails && this.filmDetails.backdrop_path) {
                return `background-image:linear-gradient(to right, rgba(187, 210, 225, 1.00) 150px, rgba(100%, 100%, 100%, 0.7) 100%), url('https://image.tmdb.org/t/p/w1280/${this.filmDetails.backdrop_path}')`;
            }
            return 'background: linear-gradient(to right, rgb(187, 210, 225) 150px, rgba(255, 255, 255, 0.7) 100%)';
        },
        getOriginalTitle() {
            if (this.filmDetails && this.filmDetails.original_title.toLowerCase() != this.filmDetails.title.toLowerCase()) {
                return this.filmDetails.original_title;
            }
            return '';
        },
        actorsSwitch() {
            this.showAllActors = !this.showAllActors;

            const icon = this.$refs.iconActorsSwitch;
            if (icon.style.transform == "rotate(180deg)") {
                icon.style.transform = "rotate(0deg)";
            }
            else {
                icon.style.transform = "rotate(180deg)";
            }
            icon.style.transition = "transform 0.5s";
        },
        playVideo() {
            this.videoPlayed = !this.videoPlayed;
        },
    },
};
</script>

<style>
/* Transition classes */
.popin-enter-active,
.popin-leave-active {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* État initial pour l'entrée */
.popin-enter-from,
.popin-leave-to {
    opacity: 0;
    /* transform: translateY(20px); */
}

/* État final pour l'entrée */
.popin-enter-to,
.popin-leave-from {
    opacity: 1;
}

/* Style for modal */
.filmModal {
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.filmModal-content {
    background-color: #fefefe;
    margin: 1.75rem auto;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    max-width: 1140px;
    /* Could be more or less, depending on screen size */
}

.popin-loader {
    display: flex;
    align-items: center;
    /* Centre verticalement */
    justify-content: center;
    /* Centre horizontalement si nécessaire */
    height: 300px;

    color: #888;
}

.filmModal-title {
    font-weight: normal;
    margin-top: 0;
}

span.original-title {
    color: rgb(108, 117, 125);
    font-size: 0.8rem;
}

/* content */
.content {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.movie-poster {
    flex: 1;
    margin-right: 40px;
    max-width: 300px;
    width: 300px;
}

.movie-poster img {
    width: 100%;
    max-width: 100%;
    /* Assurez-vous que cette image a la même proportion que celle de l'affiche du film dans l'image */
    border: 5px solid #ddd;
}

.movie-info {
    flex: 2;
}

@media (max-width: 768px) {

    /* ou un autre point de rupture qui convient à votre design */
    .movie-poster img {
        width: 100%;
        /* L'image prend toute la largeur */
    }

    .content {
        display: flex;
        flex-direction: column;
        /* Stack les enfants verticalement */
    }

    .movie-info {
        order: 2;
        /* Assure que movie-info vient après movie-poster */
    }

    .movie-poster {
        order: 1;
        /* Assure que movie-poster vient en premier */
    }
}

.movie-info h4 {
    margin-top: 0px;
    margin-bottom: 10px;
}

.movie-info h6 {
    margin-top: 10px;
    margin-bottom: 15px;
}

.movie-info p {
    margin-bottom: 15px;
}

.movie-info hr {
    height: 1px;
    border-width: 0;
    color: #acacac;
    background-color: #acacac;
}

.movie-info .icon-chevron {
    color: #333;
    margin-left: 15px;
    margin-right: 6px;
    font-size: 0.75em;
}

.popin-badge {
    padding: 2px 6px;
    /* Espacement intérieur */
    border-radius: 10px;
    /* Bordures arrondies */
    font-size: 0.7em;
    /* Réduire la taille de la police */
    margin-bottom: 4px;
    /* Espace au-dessus du badge */
    margin-right: 6px;
    /* Espace au-dessus du badge */
}

.popin-duration {
    background-color: #888;
    /* Couleur de fond */
    color: white;
    /* Couleur du texte */
}

.popin-releasedate {
    background-color: #4c559e;
    /* Couleur de fond */
    color: white;
    /* Couleur du texte */
}

.popin-genre {
    background-color: #c9c9c9;
    /* Couleur de fond */
    color: rgb(95, 95, 95);
    /* Couleur du texte */
}

.actors button {
    background-color: rgb(108, 117, 125);
    border-color: rgb(108, 117, 125);
    color: white;
    font-weight: 400;
    text-align: center;
    vertical-align: center;

    float: left;
    margin-right: 5px;

    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
    border: 1px solid transparent;

    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.actor {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem;

    font-size: 10pt;
    border-width: 1px;
    border-style: solid;
    margin-left: 3px;
    margin-right: 3px;
    word-break: keep-all;

    color: #555555;
    background-color: transparent;

    display: inline-block;
    padding: .25em .4em;
    padding-right: 0.4em;
    padding-left: 0.4em;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    /*transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;*/
}

.fadeActor-enter-active,
.fadeActor-leave-active {
    transition: opacity 0.5s;
}

.fadeActor-enter,
.fadeActor-leave-to {
    opacity: 0;
}

.actor i {
    font-weight: 400 !important;
}

.footer {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

/* end of content */

.youtube-player {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
    margin: 5px;
}

.youtube-player ::after {
    box-sizing: border-box;
}

.youtube-player img {
    object-fit: cover;
    display: block;
    left: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    height: auto;
    cursor: pointer;
    -webkit-transition: 0.4s all;
    -moz-transition: 0.4s all;
    transition: 0.4s all;
}

.youtube-player img:hover {
    -webkit-filter: brightness(75%);
    filter: brightness(75%);
}

.youtube-player .play {
    height: 72px;
    width: 72px;
    left: 50%;
    top: 50%;
    margin-left: -36px;
    margin-top: -36px;
    position: absolute;
    cursor: pointer;
}

i.yt-play {
    color: red;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 Aspect Ratio */
    height: 0;
    overflow: hidden;
}

.real-youtube-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 100;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.film-details-link {
    color: #0275d8; /* Bleu par défaut, mais peut être ajusté */
    background-color: #8fb4d4;
    text-decoration: none; /* Retirer le soulignement par défaut */
    padding: 2px 8px;
    border: 2px solid transparent; /* Préparer le terrain pour un effet de survol */
    border-radius: 4px; /* Bords arrondis */
    transition: all 0.3s ease; /* Transition douce pour les effets de survol */
}

.film-details-link:hover, .film-details-link:focus {
    color: #fff; /* Changement de couleur sur survol */
    background-color: #0275d8; /* Fond bleu sur survol */
    border-color: #0056b3; /* Bordure plus foncée pour le contraste */
    text-decoration: none; /* S'assurer que le soulignement n'apparaît pas */
}

.film-details-link i {
    margin-left: 5px; /* Espacement entre le texte et l'icône */
    font-size: 0.8rem; /* Taille appropriée de l'icône */
}

</style>

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';

import 'primevue/resources/themes/saga-blue/theme.css'; // theme
import 'primevue/resources/primevue.min.css';           // core css
import 'primeicons/primeicons.css';                     // icons

const app = createApp(App);

app.config.globalProperties.$apiUrl = process.env.VUE_APP_API_URL;
app.config.globalProperties.$posterUrl = process.env.VUE_APP_POSTER_URL;
console.log("URL API=" + process.env.VUE_APP_API_URL);

app.use(router);

app.use(PrimeVue);
app.use(ToastService);

app.mount('#app');
<template>
  <div class="login-popin" v-if="isVisible">
    <form @submit.prevent="onLogin">
      <div>
        <label for="email">Email :</label>
        <input type="text" id="email" v-model="email">
      </div>
      <div>
        <label for="password">Mot de passe :</label>
        <input type="password" id="password" v-model="password">
      </div>
      <div v-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>
      <button type="submit">Connexion</button>
    </form>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  props: {
    isVisible: Boolean
  },
  data() {
    return {
      email: localStorage.loggedUser || '',
      password: '',
      errorMessage: ''
    };
  },
  methods: {
    async onLogin() {
      try {
        const response = await axios.post(this.$apiUrl + 'users/login', {
          email: this.email,
          password: this.password
        });

        if (response.data.token) {
          console.error('Connexion réussie');
          localStorage.loggedUser = this.email;
          localStorage.token = response.data.token;
          this.$emit('login-success', this.email);
          // Gérez la navigation ou d'autres actions ici
        } else {
          console.error('Connexion refusée');
          // Gérez l'échec de la connexion ici
          this.$emit('login-failure', response.data);
          this.errorMessage = 'Mot de passe incorrect';
        }
      } catch (error) {
        console.error('Erreur de connexion:', error);
        // Gérez l'erreur de connexion ici
        this.$emit('login-error', error);
        this.errorMessage = 'Mot de passe incorrect';
      }
    },
  },
  watch: {
    isVisible(newVal) {
      if (newVal) {
        this.email = '';
        this.password = '';
        this.errorMessage = '';
      }
    }
  },
};
</script>
  
<style>
.login-popin {
  position: absolute;
  right: 0;
  top: 100%;
  background: #f9f9f9;
  /* Couleur de fond plus douce */
  border: 1px solid #ddd;
  /* Bordure plus subtile */
  border-radius: 10px;
  /* Coins plus arrondis */
  padding: 25px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Ombre portée plus légère */
  z-index: 10;
  box-sizing: border-box;
  /* Assure une largeur constante */
  text-align: left;
}

.login-popin label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  /* Texte en gras pour les labels */
}

.login-popin input[type="text"],
.login-popin input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  /* Taille correcte pour les inputs */
}

.login-popin button {
  width: 100%;
  padding: 10px 15px;
  background-color: #4CAF50;
  /* Couleur de fond pour le bouton */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.login-popin button:hover {
  background-color: #45a049;
  /* Légère variation au survol */
}

.error-message {
  color: red;
  margin-bottom: 15px;
}
</style>

<template>
  <div class="container">
    <FilmCard v-for="film in films" :key="film.id" :filmData="film" :loggedIn=loggedIn 
      :popinRouteName="popinRouteName" :searchMode=searchMode 
      @updateMovie="onUpdateMovie" @disconnected="onDisconnect" @selectMovie="onSelectMovie"/>
  </div>
  <div v-if="films.length === 0" class="main-info">
    <i class="pi pi-times" style="font-size: 3rem"></i>
    <h3>Aucun film ne correspond à vos critères</h3>
  </div>
</template>
  
<script>
import FilmCard from './FilmCard.vue';

export default {
  name: 'FilmGrid',
  components: {
    FilmCard
  },
  props: {
    films: Array,
    loggedIn: Boolean,

    popinRouteName: String, //FilmPopin
    searchMode: Boolean, //true if the card is used in search mode
  },
  emits: ['updateMovie', 'disconnected', 'selectMovie'],
  methods: {
    onUpdateMovie(movie) {
      //const index = this.films.findIndex(f => f.id === movie.id);
      //this.films[index] = movie;
      //console.log('updateMovie', movie, ' at index ', index);
      this.$emit('updateMovie', movie);
    },
    onDisconnect() {
      this.$emit('disconnected');
    },
    onSelectMovie(movie) {
      this.$emit('selectMovie', movie);
    }
  }
};
</script>
  
<style>
.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

/* Media Query pour les écrans de téléphone en mode portrait */
@media screen and (max-width: 600px) {
  .container {
    /* Définit deux colonnes pour les écrans plus petits */
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    gap: 10px;
  }
}


.main-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  color: #888;
}

.main-info h3 {
  font-style: italic;
}
</style>
  
export const formatDurationMixin = {
    methods: {
        formatDuration(minutes) {
            let hours = Math.floor(minutes / 60);
            let remainingMinutes = minutes % 60;
        
            if (hours > 0 && remainingMinutes > 0) {
                return `${hours} h ${remainingMinutes.toString().padStart(2, '0')}`;
            } else if (hours > 0) {
                return `${hours} h`;
            } else {
                return `${remainingMinutes} m`;
            }
        }
    }
};
<template>
    <div>
        <!-- List of existing files-->
        <div class="file-grid">
            <div class="file-counters">
                <span>Nombre de {{ description }}&nbsp;: {{ fileList.length }}</span>
                <button class="toggle-button" @click="toggleExistingFiles" v-show="fileList.length > 0">
                    <i :class="localIsFilesSectionVisible ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></i>
                </button>
            </div>
        </div>
        <div class="file-grid" v-show="localIsFilesSectionVisible">
            <div v-for="(file, index) in fileList" :key="file.path">
                <MovieFile ref="movieFile" :file="file" :index="index" :tag="tag" :initialStatus="file.status ? file.status : 'pending'" @file-processed="onFileProcessed" />
            </div>
        </div>
    </div>
</template>

<script>
import MovieFile from './MovieFile.vue';

export default {
    components: {
        MovieFile
    },
    props: {
        isFilesSectionVisible: Boolean,
        fileList: Array,
        tag: String,
        description: String, // for example "fichiers déjà existants"
    },
    data() {
        return {
            localIsFilesSectionVisible: this.isFilesSectionVisible,
        }
    },
    emits: ['file-processed'],
    methods: {
        toggleExistingFiles() {
            this.localIsFilesSectionVisible = !this.localIsFilesSectionVisible;
        },
        onFileProcessed(filePath, status, movie) {
            // Handle file processed event
            this.$emit('file-processed', filePath, status, movie);
        }
    }
};
</script>

<style scoped>

.file-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 0.3rem;
  /* Espacement entre les éléments de la grille */
}

@media (max-width: 500px) {
  .file-grid {
    grid-template-columns: 1fr;
  }
}

.file-grid>div {
  overflow: auto;
  /* Ajoute des barres de défilement si le contenu dépasse */
  word-wrap: break-word;
  /* Casse les lignes à l'intérieur des mots non coupables pour empêcher le débordement */
}

/* ajouter bordures aux éléments de la grille */
.file-grid>div {
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 0.8rem;
  text-align: left;
}

.file-grid .file-counters {
  font-size: 0.8rem;
  font-weight: bold;

  text-align: center;
  margin: 10px 0px;
}

.toggle-button {
  /* make the button not look like a button but like a grey chevron */
  background-color: #f1f1f1;
  border: none;
  color: black;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 0px 2px;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;

  /* align it to the right */
  float: right;
}

</style>
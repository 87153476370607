<template>
  <HeaderCatalog ref="headerCatalog" @criteria-changed="onCriteriaChanged" @login-changed="onLoginChanged" />
  <div v-if="error">{{ error }}</div>

  <FilmGrid v-if="!firstLoading && !error" :films="films" :loggedIn="loggedIn" 
    :popinRouteName="popinRouteName" :searchMode="false" 
    @updateMovie="onUpdateMovie"
    @disconnected="onDisconnect" />

  <div v-if="isLoading" class="main-loader"><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></div>

  <router-view></router-view>
</template>

<script>
import axios from 'axios';

import HeaderCatalog from './HeaderCatalog.vue';
import FilmGrid from './FilmGrid.vue';

export default {
  name: 'MovieCatalog',
  components: {
    HeaderCatalog,
    FilmGrid
  },
  data() {
    return {
      films: [],
      firstLoading: true,
      isLoading: true,
      error: null,
      currentPage: 1,
      endReached: false,
      currentCriteria: null,
      loggedIn: false,
      
      popinRouteName: "FilmPopin",
    };
  },
  mounted() {
    this.isLoading = false;
    this.fetchFilms();

    // détecte scroll pour lazy loading
    window.addEventListener('scroll', this.handleScroll);

    console.log("MovieCatalog: mounted (logged in: " + this.loggedIn + ", isLoading: " + this.isLoading + ")");
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    fetchFilms() {
      console.log("MovieCatalog: fetchFilms (logged in: " + 
        this.loggedIn + ", isLoading: " + this.isLoading + ", endReached: " + this.endReached + ")");
      if (this.isLoading || this.endReached) return; // Éviter les appels redondants

      this.isLoading = true;
      this.error = null;

      let url = `${this.$apiUrl}films/${this.currentPage}`;
      let headers = {};
      if (this.loggedIn) {
        url = `${this.$apiUrl}users/films/${this.currentPage}`;
        headers = {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}` // Assurez-vous que le token est stocké ici
          }
        };
      }

      if (this.currentCriteria) {
        const params = [];
        if (this.currentCriteria.selectedSort) {
          params.push(`sortcriteria=${encodeURIComponent(this.currentCriteria.selectedSort)}`);
        }
        if (this.currentCriteria.sortOrder) {
          params.push(`sortorder=${encodeURIComponent(this.currentCriteria.sortOrder)}`);
        }
        if (this.currentCriteria.selectedGenres) {
          params.push(`genres=${encodeURIComponent(this.currentCriteria.selectedGenres)}`);
        }
        if (this.loggedIn) {
          if (this.currentCriteria.filterDislike !== null) {
            params.push(`dislike=${encodeURIComponent(this.currentCriteria.filterDislike)}`);
          }
          if (this.currentCriteria.filterLike !== null) {
            params.push(`like=${encodeURIComponent(this.currentCriteria.filterLike)}`);
          }
          if (this.currentCriteria.filterWatched !== null) {
            params.push(`watched=${encodeURIComponent(this.currentCriteria.filterWatched)}`);
          }
        }
        if (params.length > 0) {
          url += `?${params.join('&')}`;
        }
      }

      console.log("MovieCatalog: fetchFilms (url: " + url + ")");
      axios.get(url, headers)
        .then(response => {
          const newFilms = response.data.movies;
          this.films = [...this.films, ...newFilms]; // Concaténer les nouveaux films
          this.endReached = response.data.full;
          this.currentPage++; // Incrémenter la page pour le prochain appel
          this.isLoading = false;
          this.firstLoading = false;
        })
        .catch((error) => {
          let message = 'An error occurred';
          if (error && error.response && error.response.status === 403) {
            this.disconnectUser();
            message = 'Vous avez été déconnecté. Veuillez vous reconnecter.';
          }
          else {
            if (error.response) {
              // La requête a été faite et le serveur a répondu avec un statut hors de la plage 2xx
              message += `: ${error.response.status} ${error.response.data}`;
            } else if (error.request) {
              // La requête a été faite mais aucune réponse n'a été reçue
              message += ': No response from the server';
            } else {
              // Quelque chose s'est produit lors de la mise en place de la requête qui a déclenché une erreur
              message += ': ' + error.message;
            }
          }

          this.error = message;
          this.isLoading = false;
          this.firstLoading = false;
        });

    },
    disconnectUser() {
      //console.log("Error. User has been disconnected");
      //this.loggedIn = false;
      this.$refs.headerCatalog.logout();
    },
    onDisconnect() {
      this.disconnectUser();
    },
    handleScroll() {
      const nearBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 500;
      if (nearBottom && !this.isLoading) {
        this.fetchFilms();
      }
    },
    onCriteriaChanged(criteria) {
      console.log("MovieCatalog: onCriteriaChanged");

      // Réinitialiser les films et la pagination
      this.firstLoading = true;
      this.films = [];
      this.currentPage = 1;
      this.endReached = false;

      // Stocker les critères pour les utiliser dans fetchFilms
      this.currentCriteria = criteria;

      // Recharger les films
      this.fetchFilms();
    },
    onLoginChanged(loggedIn) {
      // Réinitialiser les films et la pagination
      this.loggedIn = loggedIn;
      this.error = null;
    },
    onUpdateMovie(movie) {
      const index = this.films.findIndex(f => f.id === movie.id);
      this.films[index] = movie;
    },
  }
};
</script>

<style>
.main-loader {
  display: flex;
  align-items: center;
  /* Centre verticalement */
  justify-content: center;
  /* Centre horizontalement si nécessaire */
  height: 300px;
  /* ou toute autre hauteur */
  color: #888;
}
</style>
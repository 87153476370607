<template>
    <div :class="searchMode===true?'film-no-hover' : 'film'" @click.stop="showPopin">
        <img :src="`${this.$posterUrl}${filmData.poster_path}`" alt="Nom du film">
        <div class="film-details">
            <h2 class="film-title">{{ filmData.title }}</h2>
            <div class="button-container">
                <!--Watched-->
                <Button v-if="loggedIn===true && !filmData.watched" icon="pi pi-eye-slash"
                    class="film-watched border film-watched-false" @click.stop="onWatch" />
                <Button v-if="loggedIn===true && filmData.watched" icon="pi pi-eye" label="Vu"
                    class="film-watched border film-watched-true" @click.stop="onWatch" />

                <span>
                    <!--Dislike-->
                    <Button v-if="loggedIn===true && !filmData.disliked" icon="pi pi-thumbs-down"
                        class="film-watched film-watched-false" @click.stop="onDislike" />
                    <Button v-if="loggedIn===true && filmData.disliked" icon="pi pi-thumbs-down-fill"
                        class="film-watched film-liked-true" @click.stop="onDislike" />
                    <!--Like-->
                    <Button v-if="loggedIn===true && !filmData.liked" icon="pi pi-heart" class="film-watched film-watched-false"
                        @click.stop="onLike" />
                    <Button v-if="loggedIn===true && filmData.liked" icon="pi pi-heart-fill" class="film-watched film-liked-true"
                        @click.stop="onLike" />
                </span>
                <span class="film-duration" v-if="filmData.runtime">{{ formatDuration(filmData.runtime) }}</span>
                <Button v-if="searchMode===true" icon="pi pi-check" class="film-selection" @click.stop="selectMovie" />
            </div>
        </div>
    </div>
    <Toast position="bottom-right" />
</template>

<script>
import { formatDurationMixin } from '../mixins/formatDurationMixin';
//import FilmPopin from './FilmPopin.vue';
import Button from 'primevue/button';
import Toast from 'primevue/toast';

import axios from 'axios';

export default {
    name: 'FilmCard',
    components: {
        Button, Toast,
        //FilmPopin,
    },
    mixins: [formatDurationMixin],
    props: {
        filmData: Object,
        loggedIn: Boolean,

        popinRouteName: String, //FilmPopin
        searchMode: Boolean, //true if the card is used in search mode
    },
    emits: ['updateMovie', 'disconnected', 'selectMovie'],
    methods: {
        showPopin() {
            console.log("Click on filmCard: " + this.filmData.id);
            if (this.popinRouteName !== undefined && this.popinRouteName !== '') {
                this.$router.push({ name: this.popinRouteName, params: { id: this.filmData.id } });
            }
        },
        selectMovie() {
            console.log("Select movie: " + this.filmData.id);
            this.$emit('selectMovie', this.filmData);
        },
        onDislike() {
            this.onMarkerChange('disliked');
        },
        onLike() {
            this.onMarkerChange('liked');
        },
        onWatch() {
            this.onMarkerChange('watched');
        },
        onMarkerChange(marker) {
            if (this.loggedIn) {
                let headers = {};
                let url = `${this.$apiUrl}users/movies/${marker}/${this.filmData.id}`;
                headers = {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}` // Assurez-vous que le token est stocké ici
                    }
                };
                axios.post(url, {}, headers)
                    .then(response => {
                        if (response.data[marker] !== null) {
                            const updatedFilmData = { ...this.filmData };
                            Object.assign(updatedFilmData, response.data);

                            this.$emit('updateMovie', updatedFilmData);
                        }
                    })
                    .catch((error) => {
                        let message = 'An error occurred';
                        if (error.response && (error.response.status === 403 || error.response.status === 401)) {
                            message = 'Vous avez été déconnecté. Veuillez vous reconnecter.';

                            this.$emit('disconnected');
                        }
                        else {
                            if (error.response) {
                                // La requête a été faite et le serveur a répondu avec un statut hors de la plage 2xx
                                message += `: ${error.response.status} ${error.response.data}`;
                            } else if (error.request) {
                                // La requête a été faite mais aucune réponse n'a été reçue
                                message += ': No response from the server';
                            } else {
                                // Quelque chose s'est produit lors de la mise en place de la requête qui a déclenché une erreur
                                message += ': ' + error.message;
                            }

                            this.$toast.add({
                                severity: 'error', // 'success', 'info', 'warn' ou 'error'
                                summary: 'Erreur',
                                detail: message,
                                life: 1500, // Durée d'affichage du toast en millisecondes
                            });
                        }
                    });
            }
        },
    },
};
</script>

<style>
.film {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;

    position: relative;
}

.film:hover {
    transform: translateY(-5px);
}

.film-no-hover {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;

    position: relative;
}

img {
    width: 100%;
    height: auto;
    display: block;
}

.film-details {
    padding: 6px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.film-title {
    font-size: 1.2em;
    font-weight: normal;
    margin: 0;
    text-align: center;

    margin-bottom: 1em;
}

.button-container {
    position: absolute;
    bottom: 0;
    left: 5px;
    right: 5px;
    display: flex;
    justify-content: space-between;
}

.film-duration {
    background-color: #888;
    color: white;
    padding: 2px 6px;
    border-radius: 10px;
    font-size: 0.7em;
    margin-bottom: 4px;
}

.film-watched {
    margin-bottom: 4px;

    border: 0;
    border-radius: 20px;
    padding: 1px 4px;
    overflow: hidden;
    width: auto;

    margin-left: 3px;
    margin-right: 3px;
}

.film-watched.border {
    border: 1px solid #ccc;
}

.film-watched-true {
    background-color: #888;
    color: #af1b1b;
}

.film-liked-true {
    background-color: transparent;
    color: #af1b1b;
}

.film-watched .pi {
    font-size: 0.8rem;
}

.p-button .p-button-icon-left {
    margin-right: 2px;
}

.film-watched .p-button-label {
    font-size: 0.7rem;
}

.film-watched-false {
    background-color: transparent;
    color: #888;
}

.film-selection {
    background-color: #4CAF50; /* Green */
    width: 100%;
    color: white;
    padding: 2px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}


.film-selection:hover {
    background-color: #45a049;
}
</style>

<template>
    <div class="filter-sort-container" :class="{'logged-in': loggedIn, 'logged-out': !loggedIn}">
        <Button v-if="loggedIn" icon="pi pi-eye" class="button-icon"
            :class="filterWatched === null ? 'like-ignored' : filterWatched ? 'like-true' : 'like-false'"
            @click="onWatchedFilterChange" />
        <Button v-if="loggedIn" icon="pi pi-thumbs-down" class="button-icon"
            :class="filterDislike === null ? 'like-ignored' : filterDislike ? 'like-true' : 'like-false'"
            @click="onDislikeFilterChange" />
        <Button v-if="loggedIn" icon="pi pi-heart" class="button-icon"
            :class="filterLike === null ? 'like-ignored' : filterLike ? 'like-true' : 'like-false'"
            @click="onLikeFilterChange" />
        <div class="filter-container">
            <MultiSelect v-model="selectedGenres" :options="genres" optionLabel="name" optionValue="id" display="chip"
                placeholder="Tous les genres" @change="onGenresFilterChange" class="button" />
        </div>
        <div class="sort-container button">
            <Dropdown v-model="selectedSort" :options="sortOptions" optionLabel="label" optionValue="value"
                @change="onSortChange" class="sortList" />
            <button class="sort-order-button" @click="toggleSortOrder">
                <i class="pi" :class="sortOrderIcon"></i>
            </button>
        </div>
    </div>
</template>

<script>
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';

import axios from 'axios';

export default {
    name: 'HeaderCatalog',
    emits: ['criteria-changed'],
    components: {
        MultiSelect, Dropdown, Button, 
    },
    data() {
        return {
            sortOrder: 'DESC', // Ordre de tri

            filterLike: null,
            filterDislike: null,
            filterWatched: null,

            genres: [], // Les genres récupérés depuis l'API
            selectedGenres: [], // Les genres sélectionnés

            selectedSort: 'file_added_on', // Critère de tri
            sortOptions: [ //dropdown de tri
                { label: 'Date d\'ajout', value: 'file_added_on' },
                { label: 'Titre', value: 'title' },
                { label: 'Date de sortie', value: 'release_date' }
                // ... autres options de tri
            ],
        }
    },
    props: {
        loggedIn: Boolean,
    },
    watch: {
        loggedIn() {
            this.onSelectorChange();
        }
    },
    mounted() {
        this.fetchGenres();
        this.restoreCriteria();
        this.onSelectorChange();
    },
    computed: {
        sortOrderIcon() {
            return this.sortOrder === 'ASC' ? 'pi-sort-amount-up' : 'pi-sort-amount-down';
        }
    },
    methods: {
        toggleSortOrder() {
            this.sortOrder = this.sortOrder === 'ASC' ? 'DESC' : 'ASC';
            this.onSortChange();
        },
        onSortChange() {
            // Logique de tri
            console.log("Critère de tri:", this.selectedSort, "Ordre:", this.sortOrder);
            this.onSelectorChange();
        },
        onGenresFilterChange() {
            console.log("Filtre genres: ", this.selectedGenres);
            this.onSelectorChange();
        },
        onLikeFilterChange() {
            this.filterLike = this.filterLike === null ? true : this.filterLike === true ? false : null;
            console.log("Filtre like: ", this.filterLike);
            this.onSelectorChange();
        },
        onDislikeFilterChange() {
            this.filterDislike = this.filterDislike === null ? true : this.filterDislike === true ? false : null;
            console.log("Filtre dislike: ", this.filterDislike);
            this.onSelectorChange();
        },
        onWatchedFilterChange() {
            this.filterWatched = this.filterWatched === null ? true : this.filterWatched === true ? false : null;
            console.log("Filtre watched: ", this.filterWatched);
            this.onSelectorChange();
        },
        onSelectorChange() {
            let criteria = {
                selectedSort: this.selectedSort,
                sortOrder: this.sortOrder,
                selectedGenres: this.selectedGenres,
                filterDislike: this.filterDislike,
                filterLike: this.filterLike,
                filterWatched: this.filterWatched,
            };

            localStorage.criteria = JSON.stringify(criteria);

            console.log("Emitting criteria-changed (criteria = ", criteria, ")");
            this.$emit('criteria-changed', criteria);
        },

        restoreCriteria() {
            const savedCriteria = localStorage.getItem('criteria');
            if (savedCriteria) {
                const criteria = JSON.parse(savedCriteria);
                this.selectedSort = criteria.selectedSort;
                this.sortOrder = criteria.sortOrder;
                this.selectedGenres = criteria.selectedGenres;
                this.filterDislike = criteria.filterDislike;
                this.filterLike = criteria.filterLike;
                this.filterWatched = criteria.filterWatched;
            }
        },

        fetchGenres() {
            // Récupération des genres depuis l'API
            let url = this.$apiUrl + 'genres';

            axios.get(url)
                .then(response => {
                    this.genres = response.data;
                })
                .catch(() => {
                    let message = 'Impossible de récupérer les genres de films';
                    this.raiseError(message);
                });
        },

        raiseError(message) {
            this.$toast.add({
                severity: 'error', // 'success', 'info', 'warn' ou 'error'
                summary: 'Erreur',
                detail: message,
                life: 1500, // Durée d'affichage du toast en millisecondes
            });
        },
    },
};
</script>

<style>
@media (max-width: 600px) {
    .filter-sort-container.logged-in {
        margin-top: 5px;
        display: flex;
        flex-direction: row; /* S'assurer que les éléments sont en ligne */
        flex-wrap: wrap;
    }

    .filter-sort-container.logged-in .button-icon {
        margin-right: 5px; /* Espace entre les boutons */
        order: 1; /* Placer les boutons en premier */
    }

    .filter-sort-container.logged-in .sort-container {
        margin-left: auto; /* Pousser à droite */
        order: 2;
    }

    .filter-sort-container.logged-in .filter-container {
        width: 100%;
        order: 3; /* Placer le filter-container en dernier */
        margin-top: 10px;
    }

    .filter-sort-container.logged-out {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .filter-sort-container.logged-out .filter-container {
        order: 1;
        width: auto; /* Ajustez selon vos besoins */
    }

    .filter-sort-container.logged-out .sort-container {
        order: 2;
        margin-left: auto; /* Pousser à droite */
    }
}

.filter-sort-container {
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
    /* Aligner les éléments à droite dans le conteneur */
}

.sort-container {
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 20px;
    overflow: hidden;
    /*width: 174px;*/
}

.sort-select {
    padding: 10px;
    border: none;
    -webkit-appearance: none;
}

.sort-order-button {
    background: none;
    border: none;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.filter-container {
    display: flex;
    align-items: center;
    /*background: #fff;
    border: 1px solid #ccc;
    border-radius: 20px;*/
    overflow: hidden;
}

.button {
    cursor: pointer;

    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 20px;
    overflow: hidden;

    margin-left: 3px;
    margin-right: 3px;
}

.button-icon {
    cursor: pointer;

    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 0 0;
    overflow: hidden;

    margin-left: 3px;
    margin-right: 3px;
}

.p-multiselect .p-multiselect-label.p-placeholder,
.p-multiselect.p-multiselect-chip .p-multiselect-token,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item,
.p-dropdown .p-dropdown-label,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-family: inherit;
    font-size: 0.8rem;
    color: #495057;
}

.sort-container>.p-dropdown .p-dropdown-label {
    padding-right: 0px;
}

.sortList {
    border: none;
    /*width: 136px;*/
}

.like-ignored {
    background-color: transparent;
    color: #838383;
    /* Couleur gris clair pour l'icône */
}

.like-true {
    background-color: #14aa5f;
}

.like-false {
    background-color: #c7232b;
}
</style>